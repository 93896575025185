import React, { useState } from "react";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import { useMediaQuery, mediaQueries } from "hooks/useMediaQuery";
import { Link } from "components/core/Link";
import { ClickToCall } from "components/brand/ClickToCall";
import { storageTypes } from "config/headerMenuLinks";
import { Button } from "components/core/Button";
import HeaderMenu from "components/brand/Header/HeaderMenu";
import { Chevron } from "components/core/Icons";
import Logo from "assets/images/full-logo.svg";
import { getTollfree } from "store/modules/phone";
import "./Header.scss";

function Header() {
	const [belowLarge] = useMediaQuery([mediaQueries.belowLarge]);
	const [expanded, setExpanded] = useState(false);
	// the name of the currently open menu, enables toggling between open menus
	const [openMenuName, setOpenMenuName] = useState(null);
	const handleExpand = () => setExpanded(!expanded);
	const useMobileMenu = expanded && belowLarge;
	const [phone, setPhone] = useState({});
	const dispatch = useDispatch();
	const handleFetchNumber = async (data) => {
		const {
			payload: { result }
		} = await dispatch(getTollfree(data));
		setPhone(result);
		return result;
	};
	return (
		<div className="header">
			<div className="left-section">
				<Link
					last
					className="logo-link"
					href="/"
					underline={false}
					segmentLabel="logo"
				>
					<img
						className="icon svg logo"
						alt="icon"
						src={Logo}
					/>
				</Link>
				{belowLarge && (
					<Button
						title={`${
							expanded ? "Close" : "Open"
						} main navigation menu`}
						onClick={handleExpand}
						className={classnames("button-menu", {
							"button-expanded": expanded,
							"button-collapsed": !expanded
						})}
						segmentLabel={`mobile menu ${
							expanded ? "open" : "closed"
						}`}
					>
						<Chevron rotate={expanded ? "up" : "down"} />
					</Button>
				)}
			</div>
			<div
				className={classnames("dropdown-menu", {
					"mobile-menu": useMobileMenu
				})}
			>
				{[storageTypes].map(({ menuName, links }) => (
					<HeaderMenu
						key={menuName}
						isStacked={useMobileMenu}
						menuName={menuName}
						links={links}
						openMenuName={openMenuName}
						setOpenMenuName={setOpenMenuName}
					/>
				))}
			</div>
			<div className="right-section">
				<ClickToCall
					handleFetchNumber={handleFetchNumber}
					phone={phone}
				/>
			</div>
		</div>
	);
}

export default Header;
