import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./Typography.scss";

export function withTypography(element, defaultProps) {
	const component = ({
		className,
		children,
		size,
		largeSize,
		mediumSize,
		smallSize,
		color,
		weight,
		last,
		nowrap,
		display,
		underline,
		strikethrough,
		align,
		...rest
	}) => {
		const classNames = classnames(
			className,
			{
				last,
				nowrap,
				underline,
				strikethrough
			},
			"sf-type",
			element.match(/h\d/gi) && "sf-heading",
			!!size && `size-${size}`,
			!!largeSize && `large-size--${largeSize}`,
			!!mediumSize && `medium-size--${mediumSize}`,
			!!smallSize && `small-size--${smallSize}`,
			!!color && `color-${color}`,
			!!weight && `weight-${weight}`,
			!!display && `display-${display}`,
			!!align && `align-${align}`
		);
		return React.createElement(
			element,
			{ className: classNames, ...rest },
			children
		);
	};
	component.displayName = element.toUpperCase();
	component.propTypes = {
		size: PropTypes.oneOf([
			"tiny",
			"small",
			"standard",
			"medium",
			"large",
			"xlarge",
			"xxlarge",
			"huge"
		]),
		largeSize: PropTypes.oneOf([
			"tiny",
			"small",
			"standard",
			"medium",
			"large",
			"xlarge",
			"xxlarge",
			"huge"
		]),
		mediumSize: PropTypes.oneOf([
			"tiny",
			"small",
			"standard",
			"medium",
			"large",
			"xlarge",
			"xxlarge",
			"huge"
		]),
		smallSize: PropTypes.oneOf([
			"tiny",
			"small",
			"standard",
			"medium",
			"large",
			"xlarge",
			"xxlarge",
			"huge"
		]),
		color: PropTypes.oneOf([
			"red",
			"white",
			"blue",
			"green",
			"orange",
			"lightGray",
			"gray",
			"darkGray",
			"black",
			"lightBlue"
		]),
		weight: PropTypes.oneOf([
			"regular",
			"medium",
			"semiBold",
			"bold",
			"extraBold"
		]),
		strikethrough: PropTypes.bool,
		underline: PropTypes.bool,
		nowrap: PropTypes.bool,
		last: PropTypes.bool,
		display: PropTypes.string,
		align: PropTypes.oneOf(["center", "left", "right"])
	};
	component.defaultProps = { underline: false, ...defaultProps };
	return component;
}

export const H1 = withTypography("h1", { size: "huge" });
export const H2 = withTypography("h2", { size: "xlarge" });
export const H3 = withTypography("h3", { size: "large" });
export const H4 = withTypography("h4", { size: "standard" });
export const H5 = withTypography("h5", { size: "standard" });
export const Text = withTypography("span");
export const ListItem = withTypography("li");
export const Paragraph = withTypography("p");

export const Link = withTypography("a");
