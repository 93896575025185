/* eslint-disable max-len */
export const organizationSchema = {
	"@context": "http://www.schema.org",
	"@type": "Organization",
	name: "Storage.com",
	url: "https://www.storage.com/",
	sameAs: [
		"https://www.linkedin.com/company/storage-com",
		"https://www.twitter.com/storagecom",
		"https://www.facebook.com/SelfStorageMarketplace/"
	],
	logo: "https://www.storage.com/images/sc-logo.png",
	// eslint-disable-next-line max-len
	description:
		"Storage.com is a self-storage marketplace where you can easily browse and compare all storage facilities and units in your area. Reserve online for free with no credit card required.",

	contactPoint: {
		"@type": "ContactPoint",
		telephone: "1-833-448-1067",
		contactType: "Sales"
	}
};

export const websiteSchema = {
	"@context": "http://schema.org",
	"@type": "WebSite",
	url: "https://www.storage.com",
	potentialAction: {
		"@type": "SearchAction",
		target: "https://www.storage.com/search?{search_term_string}",
		"query-input": "required name=search_term_string"
	}
};
