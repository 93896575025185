/* eslint-disable max-len */

import React from "react";
import { Switch, Route } from "react-router-dom";
import {
	HomePage,
	SearchPage,
	ErrorPage,
	StyleGuide,
	FacilityPage,
	ConfirmationPage,
	RVStoragePage,
	SelfStoragePage,
	CheapStoragePage,
	MilitaryStoragePage,
	StudentStoragePage,
	BusinessStoragePage,
	BoatStoragePage,
	CarStoragePage,
	ClimateControlledStoragePage,
	TermsAndConditionsPage,
	PaidLandingPage,
	SizeGuidePage,
	PrivacyPolicyPage,
	HelpPage,
	ContactUsPage,
	StateLandingPage
} from "pages/Bundles";

import { getSearchPage, getLandingPage } from "./store/modules/search";
import { getStateLandingPage } from "./store/modules/state";
import { getFacilityPage } from "./store/modules/facilities";
import { getBookingDetails } from "./store/modules/booking";

export const staticRoutes = [
	{
		path: "/",
		component: HomePage
	},
	{
		path: "/search/",
		component: SearchPage,
		actions: { getSearchPage },
		loadData: ({ actions, queryParams }) =>
			actions.getSearchPage({ query: queryParams })
	},
	{
		path: "/reservation/confirmation/:bookingId/",
		component: ConfirmationPage,
		actions: { getBookingDetails },
		loadData: ({ actions, match, queryParams }) => {
			const {
				params: { bookingId }
			} = match;
			return actions.getBookingDetails({
				query: queryParams,
				path: `${bookingId}`
			});
		}
	},
	{
		path: "/rv-storage/",
		component: RVStoragePage
	},
	{
		path: "/self-storage/",
		component: SelfStoragePage
	},
	{
		path: "/cheap-storage/",
		component: CheapStoragePage
	},
	{
		path: "/military-storage/",
		component: MilitaryStoragePage
	},
	{
		path: "/college-student-storage/",
		component: StudentStoragePage
	},
	{
		path: "/business-storage/",
		component: BusinessStoragePage
	},
	{
		path: "/boat-storage/",
		component: BoatStoragePage
	},
	{
		path: "/car-storage/",
		component: CarStoragePage
	},
	{
		path: "/climate-controlled/",
		component: ClimateControlledStoragePage
	},
	{
		path: "/self-storage/:state([0-9a-zA-Z-]+)/",
		exact: true,
		component: StateLandingPage,
		actions: { getStateLandingPage },
		loadData: ({ actions, match, queryParams }) => {
			const {
				params: { state }
			} = match;
			return actions.getStateLandingPage({
				query: queryParams,
				path: `${state}`
			});
		}
	},
	{
		path: "/about-us/terms-and-conditions/",
		component: TermsAndConditionsPage
	},
	{
		path: "/paid-landing/",
		component: PaidLandingPage
	},
	{
		path: "/self-storage-size-guide/",
		component: SizeGuidePage
	},
	{
		path: "/help/",
		component: HelpPage,
		actions: { getLandingPage },
		loadData: ({ actions, match, queryParams }) =>
			actions.getLandingPage(match, queryParams, "city")
	},
	{
		path: "/about-us/privacy/",
		component: PrivacyPolicyPage
	},
	{
		path: "/help/contact/",
		component: ContactUsPage
	},
	{
		path: "/self-storage/:state([0-9a-zA-Z-]+)/:city([0-9a-zA-Z-]+)/",
		component: SearchPage,
		actions: { getLandingPage },
		loadData: ({ actions, match, queryParams }) =>
			actions.getLandingPage(match, queryParams, "city")
	},
	{
		path: "/self-storage/:state([0-9a-zA-Z-]+)/:city([0-9a-zA-Z-]+)/:amenity(twenty-four-hour-access-storage-units|climate-control-storage-units)/",
		component: SearchPage,
		actions: { getLandingPage },
		loadData: ({ actions, match, queryParams }) =>
			actions.getLandingPage(match, queryParams, "amenity")
	},
	{
		// https://storage.com/self-storage/texas/san-marcos/78666/1620-ih-35-s/
		path: "/self-storage/:state([a-zA-Z-]+)/:city([a-zA-Z-.']+)/:zip([0-9]{5})/:address([0-9a-zA-Z-.']+)/",
		component: FacilityPage,
		actions: { getFacilityPage },
		loadData: ({ actions, match, queryParams }) => {
			const {
				params: { address, state, city }
			} = match;
			return actions.getFacilityPage({
				query: queryParams,
				path: `/${state}/${city}/${address}`
			});
		}
	},
	{
		path: "/self-storage/:state([0-9a-zA-Z-]+)/:city([a-zA-Z-.']+)/:address([0-9a-zA-Z-.']+)-:facilityId([0-9]+)/",
		component: FacilityPage,
		actions: { getFacilityPage },
		loadData: ({ actions, match, queryParams }) => {
			const {
				params: { facilityId }
			} = match;
			return actions.getFacilityPage({
				query: queryParams,
				path: `/${facilityId}`

			});
		}
	},
	{
		path: "/ux/",
		component: StyleGuide
	},
	{
		component: ErrorPage
	}
];

export default function Routes() {
	return (
		<Switch>
			{staticRoutes.map((route, i) => (
				<Route
					key={route.key || i}
					exact
					strict
					{...route}
				/>
			))}
		</Switch>
	);
}
